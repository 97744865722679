<template>

    <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="hasAlias" >
    <b-card>
    <p style="margin: 0px; font-size: 12px;margin-bottom: 5px;font-weight: bold;">Comparte tu link de billetera y recibe pagos hacia tu billetera</p>
    
        <b-input-group>
    
          <b-form-input
            v-model="link"
            readonly
          />
          <b-input-group-append>
            <b-button
            @click="onCopyLink"
              variant="outline-primary"
            >
    
               <feather-icon
         icon="CopyIcon"
         size="15"
        
         /> 
            </b-button>
          </b-input-group-append>
        </b-input-group>

    
      </b-card>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-else style="margin-bottom: 10px;">

        <b-alert
                        variant="secondary"
                        show
                      >
                        <div class="alert-body">
                     

                          <b-row>
    
                            <b-col cols="7" sm="7" md="7" lg="7" xl="7">
                              <p style="margin:0px;">Registra tu alias para activar tu link de billetera</p>
                            </b-col>

                            <b-col cols="5" sm="5" md="5" lg="5" xl="5" style="display:flex">

                              <div style="margin:auto">
                                <b-button class="animacion_button" size="sm" block variant="primary"  :to="{ name: 'perfil', hash: '#alias'}" > Registrar </b-button>

                              </div>

                            
                              
                            </b-col>

                          </b-row>
                     
                        </div>
                      </b-alert>

      </b-col>
        
        </template>
        
        <script>
        import { BRow,BButton,VBTooltip,VBPopover, BCol,BInputGroup,BAlert, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,BCard } from 'bootstrap-vue'
        
        export default {
          components: {
            BRow,
            BCol,
            BButton,
            BAlert,
            BCard,
            BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,
          },
          directives: {
              'b-popover': VBPopover,
        'b-tooltip': VBTooltip,
      },
      props: ['alias','displayName'],
          data() {
        
    
    
 
    
            return {
            
              isMobile:this.$isMobile,
                link: "",
          hasAlias:false
        
            }
          },
          created() {

            if(this.alias != ""){
        
              this.link=window.location.host+"/u/"+this.alias
        
              this.hasAlias=true;
        
            }
        
          },
          mounted() {

            

            
         
    
       
        
    
          
        
          },
          methods: {
        
            onCopyLink() {

              if (this.isMobile) {

                if (navigator.share) {
                  navigator.share({
                    title: 'EQCoop - Link de billetera de' + this.displayName,
                    text: "Hola soy " + this.displayName + " y puedes realizarme pagos a través del siguiente link: ",
                    url: "u/" + this.alias,
                  })
                    .then(() => console.log('Successful share'))
                    .catch((error) => console.log('Error sharing', error));
                }

                } else {

                  navigator.clipboard.writeText(this.link);
                          this.$toast.success('Link de billetera copiado!', {
                            position: 'top-right',
                            timeout: 1500,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                          })

                }

          
        },
     
            
        
          },
        }
        </script>
        
        <style lang="scss" >
        
        
        
        </style>
        