<template>

<b-row>


    <b-col cols="12" sm="12" md="12" lg="12" xl="12"  >
 
     <b-link   style="width: 100%;" :to="{ name: 'perfil'}" @click="closeSidebar"  >
     <b-card class="hover_div">
      <b-row>
 
     <b-col style=" display: flex;   text-align: center;" cols="2" sm="2" md="2" lg="2" xl="2">
 
        <b-avatar
         size="40"
         variant="light-primary"
         style="margin: auto;"
       >

       {{ name }}
 
       </b-avatar>
 
 
 
      </b-col>
 
       
     <b-col  cols="10" sm="10" md="10" lg="10" xl="10">
 
           
         
           
                 <p  class="txt_display" style="margin-top:8px">
                   {{ displayName }}
                 </p>
 
                     <p  class="ver_perfil" >
                           Configurar mi perfil
                 </p>
 
 
         
 
 
 
 
       </b-col>
       </b-row>
       </b-card>
 
        </b-link>
 </b-col>
 <!--
 <b-col cols="12" sm="12" md="12" lg="12" xl="12"  >
 
 <b-link   style="width: 100%;" :to="{ name: 'tutoriales'}" @click="closeSidebar"  >
 <b-card class="hover_div">
  <b-row>

 <b-col style=" display: flex;   text-align: center;" cols="2" sm="2" md="2" lg="2" xl="2">

    <b-avatar
     size="40"
     variant="light-primary"
     style="margin: auto;"
   >

   <feather-icon
                                    icon="YoutubeIcon"
                                    size="18"
                                   
                                    />

   </b-avatar>



  </b-col>

   
 <b-col  cols="10" sm="10" md="10" lg="10" xl="10">

       
     
       
             <p  class="txt_display" style="margin-top:8px">
               Videos tutoriales
             </p>

                 <p  class="ver_perfil" >
                   Aprende a utilizar EQCoop al 100%
             </p>


     




   </b-col>

   </b-row>
   </b-card>

    </b-link>
</b-col>
   -->


<b-col cols="12" sm="12" md="12" lg="12" xl="12" @click="openModalRoomies" >

 <b-card class="hover_div">
  <b-row>

 <b-col style=" display: flex;   text-align: center;" cols="2" sm="2" md="2" lg="2" xl="2">

    <b-avatar
     size="40"
     variant="light-primary"
     style="margin: auto;"
   >

<img src="/img/myRommie_rosado2.svg" style="width: 50px"/>

   </b-avatar>



  </b-col>

   
 <b-col  cols="10" sm="10" md="10" lg="10" xl="10">

       
     
       
             <p  class="txt_display" style="margin-top:8px">
               Recargar saldo en cuenta My Roomies
             </p>

                 <p  class="ver_perfil" >
                  Recharge balance in My Roomies account
             </p>


     




   </b-col>
   </b-row>
   </b-card>

</b-col>
 
       <alias :alias="alias" :display-name="displayName"/>
       <b-col cols="12" sm="12" md="12" lg="12" xl="12" >
       <link-referidos :promo-code="promoCode" :display-name="displayName" :is-sidebar="true" />

       </b-col>
 
               
        
      
 
       <b-col cols="12" sm="12" md="12" lg="12" xl="12"   style="cursor:pointer;margin-bottom: 10px;">
 
         <b-card @click="changeMode" class="hover_div" style="margin: 0;">
 
         <b-row>
 
 
 
            <b-col cols="4" sm="4" md="4" lg="4" xl="4" align="center" style="display:flex">
       
 <div style="margin: auto;">
 
           <b-avatar
                     size="35"
                     variant="light-primary"
                     >
                 
                     <span class="d-flex align-items-center">
 
                       <feather-icon
                           size="21"
                           class="color_icon"
                           :icon="`${isDark ? 'Moon' : 'Sun'}Icon`"
                         />
                     
                       
                     </span>
 
                 </b-avatar>
 
                
                </div>
 
           </b-col>
           <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="display:flex">

            <p style="margin:auto">Modo oscuro</p>

            </b-col>
           <b-col cols="4" sm="4" md="4" lg="4" xl="4" style="display:flex">
      <b-form-checkbox
             style="margin:auto"
             @change="changeMode"
                   :checked="isDark"
                   class="custom-control-dark"
                   name="check-button"
                   switch
                 >
 
                 </b-form-checkbox>
          </b-col>

    
 
 
 
         </b-row>
       </b-card>
       </b-col>

    
        <b-col cols="12" sm="12" md="12" lg="12" xl="12"   style="margin-bottom: 10px" v-if="!isSidebar">
      
        <a  href="https://api.whatsapp.com/send?phone=19452074178&text=Hola,%20necesito%20contactar%20con%20soporte" target="_blank" >

          <b-card class="hover_div" style="margin: 0;">
                  
                  <b-row>



                  <b-col cols="2" sm="2" md="2" lg="2" xl="2" align="center" style="display:flex">

                  <div style="margin: auto;">

                  <b-avatar
                              size="35"
                              variant="light-primary"
                              >
                          
                              <span class="d-flex align-items-center">

                                <svg width="21" height="21"  viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentcolor"><path d="M13.601 2.326A7.854 7.854.0 007.994.0C3.627.0.068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933.0 003.79.965h.004c4.368.0 7.926-3.558 7.93-7.93A7.898 7.898.0 0013.6 2.326zM7.994 14.521a6.573 6.573.0 01-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251A6.56 6.56.0 011.407 7.922c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56.0 014.66 1.931 6.557 6.557.0 011.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729.0 00-.529.247c-.182.198-.691.677-.691 1.654.0.977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/></svg>

                              
                              
                              </span>

                          </b-avatar>

                      
                      </div>

                  </b-col>

                  <b-col cols="10" sm="10" md="10" lg="10" xl="10" align="center" style="display:flex">

                      <div style="margin: auto;">

                    

                              <p style="text-align: center; margin-bottom:0px;" >¿Dudas o problemas? Contáctanos</p>
                              </div>

                      </b-col>






                  </b-row>
                  </b-card>

                </a>
              </b-col>


 
 
 
      
  </b-row>

        
        </template>
        
        <script>
        import { BRow,BButton,BAvatar,BAlert,VBTooltip,VBPopover,BLink,BCard,BFormCheckbox, BCol,BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup, } from 'bootstrap-vue'
        
        import Alias from './Alias.vue'
        import LinkReferidos from './LinkReferidos.vue'

        export default {
          components: {
            BRow,
            Alias,
            BCol,
            LinkReferidos,
            BAvatar,
            BLink,
            BAlert,
            BFormCheckbox,
            BCard,
            BButton,
            BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,
          },
          props: ['displayName','alias','promoCode', 'isSidebar','userId','tokenAuth','isProfileVerificated'],
          directives: {
              'b-popover': VBPopover,
        'b-tooltip': VBTooltip,
      },
          data() {
            let modo= localStorage.getItem('skin');

    
         
                if(modo === "light"){

                    modo= false;

                }else{
                    modo= true;

                }
                let name= this.displayName.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
 
    
            return {
              name:name,
                isDark:modo,
  
             
        
            }
          },
          created() {
        
          },
          mounted() {


              
        
          },
          methods: {

            openModalRoomies(){

              this.$eventBus.$emit('openModalRoomie', "")

         

            },

        
            closeSidebar(){
                this.$eventBus.$emit('closeSidebar');
            },
      


                changeMode(){

                let modo=localStorage.getItem('skin');

                if(modo === "light"){

                    this.isDark= true;
                    localStorage.setItem('skin', "dark");
                    document.body.classList.add('dark-layout')

                    }else{

                    this.isDark= false;
                    localStorage.setItem('skin', "light")
                    document.body.classList.remove('dark-layout')

                    }




                },
       
        
          },
        }
        </script>
        
        <style lang="scss" >
        
        
        
        </style>
        